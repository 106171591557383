import logo from './logo.svg';
import './App.css';
import Chatbot from './Components/Chatbot/chatbot';
import HomePage from './Components/Home/index';
import SpeechToText from './Components/Chatbot/stt';
import Sttv2 from './Components/Chatbot/sttv2';

function App() {
  const path = window.location.pathname;
  return (<Chatbot/>)

  // switch (path) {
  //   case "/chat":
  //     return (<><div>This is chatbot page</div><Chatbot/></>)
  //   case "/home":
  //     return (<><HomePage/></>)
  //   case "/stt":
  //     return (<><SpeechToText/></>)
  //   case "/stt2":
  //     return (<><Sttv2/></>)
  //   default:
  //     return (<>This is homepage</>)
  // }
}

export default App;
