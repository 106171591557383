import chatbotIcon from "../../assets/chatheader.svg";

const ChatbotHeader = ({height, width}) => {
    // return <img src={chatbotIcon} alt="GenInsight.ai" style={{ height: height, width: width, fill: '#0093FB' }}/>
    return <svg viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: height, width: width, fill: '#0093FB', marginTop:"6px" }}>
    <defs>
        <filter id="filter0_d_283_1823" x="0" y="0" width="81" height="81" filterUnits="userSpaceOnUse">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape"/>
        </filter>
    </defs>
    <g filter="url(#filter0_d_283_1823)">
        <circle cx="40.5" cy="36.5" r="29.5" fill="#0093FB"/>
        <circle cx="40.5" cy="36.5" r="29.5" stroke="white" stroke-width="3" fill="none"/>
    </g>
    <path d="M38.3564 41.4575C38.293 41.3583 37.5495 41.0736 36.8922 40.8214C35.3612 40.2341 33.0477 39.3474 32.0406 38.3029C31.3605 37.5968 31.1181 36.4254 31.1181 35.5647C31.1181 35.5468 30.9831 33.5506 31.5004 32.5077C31.764 31.9773 32.5026 31.307 33.4983 31.0939C34.1686 30.9507 35.1904 30.9637 36.2593 31.8406C37.4486 32.8168 37.9725 33.4155 38.2246 33.7035C38.2637 33.7474 38.3011 33.7897 38.332 33.8239L38.3223 30.0689C38.3223 29.8818 38.2295 29.7077 38.075 29.6036L28.3848 23.0063C28.0985 22.8127 27.7357 22.7915 27.4314 22.9526C27.1223 23.1153 26.9385 23.4212 26.9385 23.771V36.5084C26.9385 37.9905 27.6787 39.3588 28.9185 40.1706L30.015 40.8881L37.4421 45.7446C37.6227 45.8633 37.8423 45.8715 38.0327 45.769C38.223 45.6665 38.3353 45.4761 38.3353 45.2614C38.3678 44.8058 38.3955 44.2982 38.4134 43.8248L38.4215 43.6246C38.4654 42.3817 38.441 41.591 38.3548 41.4559L38.3564 41.4575Z" fill="white"/>
    <path d="M52.0537 36.5101V23.7727C52.0537 23.4229 51.8698 23.1187 51.5607 22.9544C51.2516 22.7917 50.8953 22.8112 50.6073 23.008L40.9172 29.6053C40.7626 29.7095 40.6715 29.8835 40.6699 30.0706L40.6601 33.8256C40.691 33.7915 40.7285 33.7492 40.7675 33.7052C41.0213 33.4157 41.5468 32.8169 42.7329 31.8424C43.3918 31.3022 44.106 31.0273 44.8544 31.0273C45.0675 31.0273 45.2839 31.0501 45.4938 31.0956C46.4895 31.3088 47.2281 31.9774 47.4917 32.5078C48.0058 33.5393 47.8805 35.49 47.874 35.573C47.874 36.4271 47.6316 37.5953 46.9515 38.303C45.9461 39.3475 43.6309 40.2342 42.1 40.8215C41.4443 41.0737 40.7008 41.3584 40.6357 41.4577C40.5462 41.5976 40.5218 42.4257 40.5723 43.7321C40.5723 43.737 40.5723 43.7452 40.5723 43.7517C40.5723 43.7582 40.5723 43.7647 40.5723 43.7728L40.5788 43.8184H40.5723C40.5918 44.2951 40.6194 44.8027 40.6504 45.2533C40.6504 45.4779 40.7642 45.6666 40.953 45.7691C41.1433 45.8716 41.363 45.8618 41.5436 45.7447L50.0672 40.1724C51.3069 39.3622 52.0471 37.9923 52.0471 36.5101H52.0537Z" fill="white"/>
    <path d="M39.2787 18.7495H39.5716C40.0027 18.7495 40.3525 19.0993 40.3525 19.5304V23.7231C40.3525 24.1542 40.0027 24.504 39.5716 24.504H39.2787C38.8476 24.504 38.4978 24.1542 38.4978 23.7231V19.5304C38.4978 19.0993 38.8476 18.7495 39.2787 18.7495Z" fill="white"/>
    <path d="M46.2013 55.5445C45.0331 55.8113 42.2201 56.384 39.5682 56.384C36.9163 56.384 33.9878 55.7739 32.7334 55.4924C33.2914 56.7078 33.8527 57.4122 34.401 57.5831C38.034 58.7187 41.462 58.7203 44.5874 57.5847C45.1177 57.3927 45.6595 56.7061 46.2013 55.5445Z" fill="white"/>
    <path d="M28.7328 20.1556C28.5897 20.2532 28.5067 20.4094 28.5083 20.5835C28.51 20.7576 28.5946 20.9121 28.741 21.0081L38.0406 27.0913C38.7337 27.5436 39.6318 27.5501 40.3297 27.1043L50.0199 20.9089C50.1679 20.8145 50.2574 20.6534 50.2574 20.4777C50.2574 20.302 50.1695 20.1409 50.0215 20.0449L47.0247 18.1186C46.8538 18.008 46.6326 18.0096 46.4634 18.1235L40.5982 22.0298V23.7251C40.5982 24.2897 40.1378 24.7501 39.5732 24.7501H39.2804C38.7158 24.7501 38.2554 24.2897 38.2554 23.7251V22.0982L32.2845 18.1235C32.1104 18.008 31.8842 18.008 31.7118 18.1268L28.7361 20.1556H28.7328Z" fill="white"/>
    <path d="M30.5925 47.5806L30.6429 47.8816C31.1359 50.7873 31.8534 53.4572 32.6099 55.2127C33.5779 55.4372 36.8383 56.14 39.5684 56.14C42.2984 56.14 45.1927 55.5315 46.3267 55.2647C47.965 51.5211 49.1055 44.4943 48.8892 41.3543V41.3461V41.2388L41.6818 45.9504C41.4296 46.1147 41.1058 46.1278 40.8423 45.9846C40.5738 45.8398 40.4127 45.5713 40.4111 45.2655C40.3965 45.0556 40.3591 44.5008 40.333 43.8663C40.0369 43.9314 39.7896 43.9688 39.5391 43.9867H39.4545C39.2023 43.9688 38.942 43.9314 38.6573 43.8728C38.6296 44.5366 38.5906 45.1093 38.5792 45.272C38.5792 45.5713 38.4181 45.8398 38.1497 45.9862C37.8861 46.1294 37.5607 46.1164 37.3101 45.952L30.1028 41.2404V41.3461C30.0035 42.8023 30.1825 45.0719 30.5925 47.5806Z" fill="white"/>
    <path d="M43.4845 34.9496C43.2405 34.6177 42.9135 34.4485 42.5116 34.4485C42.1098 34.4485 41.7828 34.6209 41.5436 34.9593C41.3272 35.2652 41.2101 35.6476 41.1938 36.095C41.1775 36.5619 41.2817 37.0207 41.4785 37.3526C41.7226 37.7642 42.0691 37.9725 42.51 37.9725C42.9135 37.9725 43.2405 37.7919 43.478 37.4356C43.6912 37.1167 43.8083 36.7213 43.8262 36.2577C43.8441 35.7549 43.7172 35.2668 43.4829 34.9496H43.4845ZM43.6651 36.2528C43.6489 36.6839 43.5415 37.0516 43.3446 37.3461C43.1347 37.6601 42.8614 37.8114 42.5116 37.8114C42.126 37.8114 41.8348 37.6341 41.6201 37.2712C41.4378 36.9638 41.3418 36.5375 41.3581 36.1015C41.3728 35.6866 41.4801 35.3335 41.677 35.0553C41.7453 34.9594 41.8202 34.8796 41.9015 34.8162C41.8657 34.852 41.8299 34.891 41.799 34.9415C41.581 35.2831 41.581 35.8379 41.799 36.1796C42.017 36.5212 42.3701 36.5212 42.5897 36.1796C42.8077 35.8379 42.8077 35.2831 42.5897 34.9415C42.4547 34.73 42.2692 34.6502 42.0951 34.7007C42.2204 34.6437 42.3587 34.6144 42.5133 34.6144C42.8647 34.6144 43.1413 34.756 43.356 35.0488C43.5675 35.3368 43.6847 35.7891 43.6668 36.2544L43.6651 36.2528Z" fill="white"/>
    <path d="M36.4821 34.4485C36.0786 34.4485 35.7532 34.6209 35.514 34.9593C35.2977 35.2652 35.1805 35.6476 35.1643 36.095C35.148 36.5619 35.2521 37.0207 35.449 37.3526C35.693 37.7642 36.0396 37.9725 36.4805 37.9725C36.8839 37.9725 37.211 37.7919 37.4485 37.4356C37.6616 37.1167 37.7788 36.7213 37.7967 36.2577C37.8146 35.7549 37.6877 35.2668 37.4534 34.9496C37.2093 34.6177 36.8823 34.4485 36.4805 34.4485H36.4821ZM37.6356 36.2511C37.6193 36.6823 37.5119 37.05 37.3151 37.3445C37.1052 37.6585 36.8319 37.8098 36.4821 37.8098C36.0965 37.8098 35.8053 37.6324 35.5905 37.2696C35.4083 36.9621 35.3123 36.5359 35.3286 36.0998C35.3432 35.685 35.4506 35.3319 35.6475 35.0537C35.7158 34.9577 35.7906 34.878 35.8736 34.8146C35.8378 34.8503 35.802 34.8894 35.7711 34.9398C35.5531 35.2815 35.5531 35.8363 35.7711 36.1779C35.9891 36.5196 36.3422 36.5196 36.5618 36.1779C36.7798 35.8363 36.7798 35.2815 36.5618 34.9398C36.4268 34.7283 36.2413 34.6486 36.0672 34.699C36.1925 34.6421 36.3308 34.6128 36.4853 34.6128C36.8368 34.6128 37.1133 34.7544 37.3281 35.0472C37.5396 35.3352 37.6567 35.7875 37.6388 36.2528L37.6356 36.2511Z" fill="white"/>
    <path d="M27.6657 47.7382C26.4406 46.7669 25.1684 46.7246 24.7161 46.7458C24.5696 46.9752 24.4281 47.2062 24.2947 47.4421C24.7486 47.4258 26.0778 47.4828 27.3517 48.5484C27.9732 49.069 28.6923 50.343 28.8648 51.0979C28.9819 50.8685 29.1039 50.6244 29.2292 50.3674C29.1853 49.7833 28.3718 48.2979 27.6641 47.7366L27.6657 47.7382Z" fill="white"/>
    <path d="M27.2475 48.6711C25.9134 47.555 24.4801 47.5892 24.1986 47.6071C23.32 49.1999 22.788 50.9114 23.0809 52.5335C23.0988 52.6327 23.1248 52.7255 23.1557 52.8133C23.4323 53.5276 24.0977 53.8595 24.5045 54.001C25.4074 54.3134 26.4633 54.2076 27.1092 53.7602C27.1483 53.7309 27.1873 53.7 27.2231 53.6691C27.594 53.3388 28.1082 52.5465 28.7427 51.3328C28.7101 50.7455 27.939 49.247 27.2491 48.6695L27.2475 48.6711Z" fill="white"/>
    <path d="M29.8606 41.3424L29.8574 41.0789C29.3221 41.5198 26.6685 43.7747 24.8252 46.5763C25.3751 46.5649 26.5807 46.6674 27.7683 47.6078C28.4061 48.1122 29.1594 49.3747 29.3497 50.1247C29.6751 49.4511 30.0233 48.6914 30.3959 47.847C29.9696 45.2617 29.7646 42.7709 29.8622 41.3424H29.8606Z" fill="white"/>
    <path d="M51.3345 47.6217C52.5596 46.6505 53.8319 46.6082 54.2842 46.6293C54.4306 46.8587 54.5721 47.0897 54.7055 47.3256C54.2516 47.3094 52.9224 47.3663 51.6485 48.432C51.027 48.9526 50.3079 50.2265 50.1354 50.9814C50.0183 50.752 49.8963 50.508 49.771 50.2509C49.8149 49.6668 50.6284 48.1814 51.3361 47.6201L51.3345 47.6217Z" fill="white"/>
    <path d="M51.7525 48.5581C53.0866 47.442 54.5199 47.4761 54.8014 47.494C55.68 49.0868 56.212 50.7984 55.9191 52.4204C55.9012 52.5197 55.8752 52.6124 55.8443 52.7003C55.5677 53.4145 54.9023 53.7464 54.4955 53.888C53.5926 54.2003 52.5367 54.0946 51.8908 53.6472C51.8517 53.6179 51.8127 53.587 51.7769 53.5561C51.406 53.2258 50.8918 52.4335 50.2573 51.2198C50.2899 50.6324 51.061 49.134 51.7509 48.5564L51.7525 48.5581Z" fill="white"/>
    <path d="M49.1398 41.2294L49.143 40.9658C49.6783 41.4067 52.3318 43.6617 54.1752 46.4633C53.6269 46.4519 52.4197 46.5544 51.232 47.4948C50.5943 47.9991 49.841 49.2616 49.6506 50.0117C49.3252 49.3381 48.9771 48.5783 48.6045 47.7339C49.0308 45.1487 49.2357 42.6579 49.1381 41.2294H49.1398Z" fill="white"/>
    <path d="M40.3886 15.2782C40.1754 15.0927 39.8858 15 39.5279 15C39.17 15 38.8836 15.096 38.677 15.2831C38.485 15.4572 38.3842 15.6654 38.3712 15.9192C38.3565 16.1844 38.4476 16.4447 38.6217 16.6318C38.8365 16.8629 39.1326 16.9751 39.5279 16.9751C39.8842 16.9751 40.1689 16.8742 40.3772 16.6774C40.5692 16.4952 40.67 16.2755 40.6847 16.0087C40.701 15.7256 40.5903 15.4523 40.3886 15.2766V15.2782Z" fill="white"/>
    <defs>
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="5.15"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_283_1823"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_283_1823" result="shape"/>
    </defs>
    </svg>
    
}

export default ChatbotHeader;