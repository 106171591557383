import React, { useEffect, useRef, useState } from "react";

import { Button, Card, Typography,Tooltip, Avatar, Box } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./chatbot.css";
import { BotMessages, UserMessages, LoaderMessages } from "./Messages";
import { getReplyForUserQuery } from "../../Common/apiCalls";
import ChatbotLogo from "../Icons/chatbotLogo.jsx";
import ChatLogo from "../Icons/chatLogo.jsx"
import ChatbotHeader from "../Icons/chatHeaderLogo.jsx";
import MaximiseIcon from "../Icons/maximiseIcon.jsx";
import MinimiseIcon from "../Icons/minimiseIcon.jsx";

const allMessages = [];


const Chatbot = () => {

    const STOP_TIMEOUT = 5000;

    const [isBotOpen, setIsBotOpen] = useState(false);
    const [isMinBotOpen, setIsMinBotOpen] = useState(true);
    const [isAnswer, setIsAnswer] = useState(true);
    const [answerText, setAnswerText] = useState("");
    const [replyText, setReplyText] = useState("");
    const [allMessagesList, setAllMessagesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isListening, setIsListening] = useState(false);
    const recognitionRef = useRef(null);
    const silenceTimeoutRef = useRef(null);

    useEffect(()=>{
        if (allMessages?.length > 0) {
            setIsAnswer(true);
        }
        else {
            let answerMessage = "Hi there! I'm here to help you with any questions related to Corestrat. Let's have a chat!";
            addToAllMessage(answerMessage, "bot");
            setAllMessagesList(allMessages);
            setAnswerText(answerMessage);
            setIsAnswer(true);
        }
        
    }, []);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition){
            alert("error: Speech recognition not supported");
            return (<></>)
        }

        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onresult = (event) => {
            let interimTranscript = "";
            for (let i = event.resultIndex; i < event.results.length; i++) {
                const result = event.results[i];
                if (result.isFinal) {
                    setReplyText(prev => prev + result[0].transcript + " ");
                    resetSilenceTimer();
                } else {
                    interimTranscript += result[0].transcript;
                }
            }
        };

        recognition.orerror = (event) => {
            console.error("Speech recognition error", event.error);
        }

        recognitionRef.current = recognition;

        if (isListening) {
            recognition.start();
            resetSilenceTimer();
        } else {
            recognition.stop();
            handleReplyButtonClick();
        }

        return () => {
            recognition.stop();
        }
    }, [isListening]);

    const resetSilenceTimer = () => {
        clearTimeout(silenceTimeoutRef.current);
        silenceTimeoutRef.current = setTimeout(() => {
            if (isListening) {
                setIsListening(false);
            }
        }, STOP_TIMEOUT);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleReplyButtonClick();
        }
    };

    const addToAllMessage = (message, from) => {
        if (allMessages?.length > 0 && allMessages[allMessages.length - 1].from === from){
            let newItem = allMessages[allMessages.length - 1]
            newItem.messages.push(message);
            // setAllMessages(allMessages.map((item, index) => index == allMessages.length - 1 ? newItem: item));
            allMessages[allMessages.length - 1] = newItem;
        } else {
            // setAllMessages([...allMessages, {from: from, messages: [message]}]);
            allMessages.push({from: from, messages: [message]});
        }
    }

    const handleChatbotButtonClick = () => {
        if(isBotOpen || isMinBotOpen){
            setIsBotOpen(false);
            setIsMinBotOpen(false);
        } else {
            setIsMinBotOpen(true);
        }
        // setIsBotOpen(prevState => !prevState);
    }

    const handleReplyTextChange = (event) => {
        setReplyText(event.target.value);
    }

    const handleInterchangeChat = (event) => {
        if(isBotOpen || !isMinBotOpen){
            setIsBotOpen(false);
            setIsMinBotOpen(true);
        } else {
            setIsBotOpen(true);
            setIsMinBotOpen(false);
        }
    }

    const handleReplyButtonClick = async () => {
        if(replyText != ""){
            setIsLoading(true);
            addToAllMessage(replyText, "user");
            setIsBotOpen(false);
            setIsMinBotOpen(true);
            setIsAnswer(true);
            const apiResponseData = await getReplyForUserQuery(replyText);
            addToAllMessage(apiResponseData, "bot");
            setAnswerText(apiResponseData);
            setReplyText("");
            setAllMessagesList(allMessages);
            setIsLoading(false);
        }
    }

    const handleCloseAnswerClick = () => {
        setIsAnswer(false);
    }

    const handleAudioRecordClick = () => {
        setIsListening(prev => !prev);
    }

    return (
        <>
            <div className="chatbot-container">
                <div className="chatbot-body">
                    <Card sx={{ borderRadius: '10px', overflow: 'visible', border: 'none', boxShadow: 'none'}}>
                        {isBotOpen && !isMinBotOpen && (
                            <div className="chatbot-chat-body">
                                <div className="chatbot-chat-body-header">
                                    <ChatbotHeader width="20%" height="80%"/>
                                    <div class="chatbot-chat-body-header-text">
                                        GenInsight.ai
                                    </div>
                                    <div className="chatbot-chat-body-header-contract">
                                        <Button size="sm" className="minimize-button" onClick={handleInterchangeChat} disableRipple>
                                            <Tooltip title="Minimize Chatbot" placement="top">
                                                <MinimiseIcon/>
                                            </Tooltip>
                                        </Button>
                                    </div>
                                </div>

                                <div className="chatbot-chat-body-message">
                                {allMessagesList.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item.from === "bot" && 
                                            <div className="bot-message">
                                                <BotMessages messages={item.messages} />
                                            </div>
                                        }
                                        {item.from === "user" && 
                                            <div className="user-message">
                                                <UserMessages messages={item.messages} />
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                                </div>
                                <div className="chatbot-chat-body-reply">
                                    <div className="chatbot-chat-body-reply-record">
                                        <Button className="chatbot-chat-body-button" onClick={handleAudioRecordClick}>
                                            {isListening ? <FiberManualRecordIcon color="error"/> : <KeyboardVoiceIcon/>}
                                        </Button>
                                    </div>
                                    <div className="chatbot-chat-body-reply-text">
                                        <input placeholder={"Write a message"} value={replyText} onChange={handleReplyTextChange} onKeyDown={handleKeyDown} style={{width: "100%", height: "80%", marginTop: "10px"}}></input>
                                    </div>
                                    <div className="chatbot-chat-body-reply-button">
                                        <Button className="chatbot-chat-body-button" onClick={handleReplyButtonClick} disableRipple><SendRoundedIcon/></Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isBotOpen && isMinBotOpen && isAnswer && (
                            <div className="chatbot-answer-body">
                                {isLoading ? (
                                    <>
                                        <div className="bot-message">
                                                <LoaderMessages/>
                                        </div>
                                        {/* <div className="chatbot-answer-body-loader">
                                            <CircularProgress/>
                                        </div> */}
                                    </>
                                ) : (
                                    <>
                                        <div className="chatbot-answer-body-header">
                                            <div className="chatbot-answer-body-header-title">
                                                <ChatbotLogo width="5rem" height="auto"/>
                                            </div>
                                            <Button size="sm" className="maximise-button" onClick={handleInterchangeChat} sx={{height: 20, color: '#8E8E8E', marginRight: "10px", '&:hover': {color: 'black', backgroundColor: 'transparent'}}} disableRipple>
                                                <Tooltip title="See Chat History" placement="top">
                                                    <MaximiseIcon/>
                                                </Tooltip>
                                            </Button>
                                        </div>
                                        <div className="chatbot-answer-body-text">
                                        <Typography sx={{ fontSize: '85%' }}>      {answerText?.split('\n').map((line, index) => (        <React.Fragment key={index}>          {line}          <br /></React.Fragment> ))} </Typography>
                                        </div>
                                        <div className="chatbot-answer-body-reply">
                                            <div className="chatbot-answer-body-reply-text">
                                                <input placeholder={"Write a message"} value={replyText} onChange={handleReplyTextChange} onKeyDown={handleKeyDown} style={{width: "100%", height: "80%", fontWeight: 400, fontFamily: "'SF Pro Text', 'SF Pro Display', sans-serif"}}></input>
                                            </div>
                                            <div className="chatbot-answer-body-reply-button">
                                                <Button className="chatbot-chat-body-button" onClick={handleReplyButtonClick} disableRipple><SendRoundedIcon/></Button>
                                            </div>
                                        </div>
                                    </>
                                )}   
                            </div>
                        )}
                    </Card>
                </div>
                <div className="chatbot-button">
                    <Button size="sm" 
                        sx={{
                                borderRadius: 100, 
                                width:70, 
                                height: 65, 
                                backgroundColor: "transparent",
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    transform: 'scale(1.1)', 
                                    zIndex: 1, 
                                },
                            }} 
                        onClick={handleChatbotButtonClick} 
                        disableRipple
                    >
                        {isBotOpen || isMinBotOpen ? (
                            <Box
                                sx={{
                                backgroundColor: '#0093FB',
                                color: 'white',
                                width: '100%',
                                height: '100%',
                                flexShrink: 0,
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                }}
                            >
                                <CloseRoundedIcon sx={{ fontSize: '2rem' }} />
                            </Box>
                        ) : (
                            <Tooltip title="Click Me">            
                                <ChatLogo width="100px" height="100px"/>
                            </Tooltip>
                        )}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Chatbot;