import axios, { Axios } from "axios";
import { FetchSessionId } from "./basicUtils";

const BACKEND_URL = "https://genai-backend-dev.southindia.cloudapp.azure.com"
const GET_REPLY = "/api/v1/chat/ask"

export async function getReplyForUserQuery (userQuery) {
    try {
        let uri = `${BACKEND_URL}${GET_REPLY}?sessionId=${FetchSessionId()}&query=${userQuery}`
        const result = await axios.get(uri);

        if (result.status === 200 && result?.data?.response_data){
            return result.data.response_data;
        } else {
            return "Sorry, cannot connect at the moment"
        }

    } catch (error) {
        console.log(error);
    }
}

export async function getTranscriptionFromAudioChunk(session_id, audio_file, chunk_no){
    try {
        const formData = new FormData();
        formData.append('file', audio_file);
        const result = await axios.post(`http://20.219.70.227:8088/api/v1/stt/transcribeAudioChunks?session_id=${session_id}&is_last_chunk=${true}`,
            formData, {headers: {'Content-Type': 'multipart/form-data'}},
        );
        if (result.status === 200 && result?.response_data){
            return result.response_data
        } else {
            return {}
        }
    } catch (error) {
        console.log(error);
    }
}