import chatbotIcon from "../../assets/chat_logo.svg";

const ChatLogo = ({height, width}) => {
    // return <img src={chatbotIcon} alt="GenInsight.ai" style={{ height: height, width: width, fill: '#0093FB' }}/>
    return <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: height, width: width, fill: '#0093FB' }}>
    <circle cx="48" cy="48" r="48" fill="#0093fb"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.8888 74.1783C33.5909 73.957 22.1794 62.1867 22.4006 47.8888C22.6219 33.5908 34.3922 22.1794 48.6901 22.4006C62.9881 22.6219 74.3995 34.3921 74.1783 48.6901C74.1142 52.8315 73.0812 56.7306 71.2985 60.1753C70.8247 61.0906 70.6555 62.1427 70.9065 63.1422L72.3593 68.9292C72.9899 71.4412 70.6658 73.6943 68.1744 72.9864L62.4353 71.3553C61.4439 71.0735 60.3871 71.2102 59.4575 71.6552C55.9595 73.3306 52.0303 74.2424 47.8888 74.1783ZM58.5949 51.6858C59.6672 51.7024 60.5231 52.5852 60.5065 53.6575C60.4899 54.7298 59.6071 55.5857 58.5348 55.5691L44.296 55.3488C43.2236 55.3322 42.3677 54.4494 42.3843 53.3771C42.4009 52.3048 43.2837 51.4489 44.356 51.4655L58.5949 51.6858ZM60.6467 44.5964C60.6633 43.5241 59.8075 42.6413 58.7351 42.6247L38.0241 42.3042C36.9517 42.2876 36.0689 43.1435 36.0523 44.2159C36.0358 45.2882 36.8917 46.171 37.964 46.1876L58.675 46.5081C59.7474 46.5247 60.6301 45.6688 60.6467 44.5964Z" fill="#F0EDE4"/>
    </svg>
    
}

export default ChatLogo;