import React, { useEffect, useState } from "react";
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import "./messages.css";
import { Typography } from "@mui/material";
import ChatbotLogo from "../Icons/chatbotLogo";

export const BotMessages = ({messages}) => {
    return (
        <div className="bot-messages-container">
            <div className="bot-message-icon">
                <ChatbotLogo width="75%" height="auto%"/>
            </div>
            <div className="bot-messages-body">
                <div className="bot-messages-body-title">GenInsight.ai</div>
                {messages.map((item, index) => (
                    <div className="bot-messages-body-item">
                        <Typography  sx={{ fontSize: '85%' }}>      {item?.split('\n').map((line, index) => (        <React.Fragment key={index}>          {line}          <br /></React.Fragment> ))} </Typography>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const UserMessages = ({messages}) => {
    return (
        <div className="user-messages-container">
            <div className="user-messages-body">
                {messages.map((item, index) => (
                    <div className="user-messages-body-item">
                        <Typography sx = {{ mr: 1,fontSize: '85%' }}>      {item?.split('\n').map((line, index) => (        <React.Fragment key={index}>          {line}          <br /></React.Fragment> ))} </Typography>
                    </div>
                ))}
            </div>
            {/* <div className="user-message-icon">
                <AccountCircleIcon color="primary" sx={{width: "80%", height: "auto", aspectRatio: "1/1"}}/>
            </div> */}
        </div>
    )
}

export const LoaderMessages = () => {
    return (
        <div className="loader-messages-container">
            <div className="loader-message-icon">
                <ChatbotLogo width="80%" height="auto%"/>
            </div>
            <div className="loader-messages-body">
                <div className="loader-messages-body-title">GenInsight.ai</div>
                        <div className="typing-loader">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
            </div>
        </div>
    )
}
 